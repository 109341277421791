@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .card {
        @apply relative flex flex-col justify-between pt-4 pb-7 px-4 rounded bg-white transition-transform duration-500 shadow-md;
    }
    .card-header {
        @apply flex flex-col items-center mb-6 text-center transition-opacity duration-500;
    }
    .navlink {
        @apply flex items-center justify-center w-6 h-6 rounded-full bg-white text-white aria-page:bg-dark-chocolate-100 aria-page:p-4 aria-page:text-2xl aria-page:font-bold;
    }
    .pill {
        @apply px-6 py-1.5 rounded-full shadow-sm;
    }
}

@font-face {
    font-family: 'Cera Pro';
    src: url('../fonts/cerapro-bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: 'Cera Pro';
    src: url('../fonts/cerapro-medium.woff') format('woff');
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: 'Cera Pro';
    src: url('../fonts/cerapro-regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: 'Cera Pro';
    src: url('../fonts/cerapro-thin.woff') format('woff');
    font-style: normal;
    font-weight: 100;
}

body {
    overscroll-behavior: none;
}

.loading-wrapper {
    perspective: 350px;
}

.break-word {
    word-break: break-word;
    hyphens: auto;
}
