.pie_chart {
    --border: 1.2rem;
    --ring-bg: #f1edea;
    --ring-vg: #a1c1ce;

    .pie {
        display: inline-grid;
        position: relative;
        transition: 1s ease-out;
        margin: 0.313rem;
        width: 9.375rem;
        height: 9.375rem;
        font-size: 1.563rem;
        font-weight: 700;
        aspect-ratio: 1;
        place-content: center;

        &::before {
            content: '';
            position: absolute;
            border-radius: 50%;
            inset: 0;
            background: conic-gradient(var(--ring-vg) calc(var(--percent) * 1%), var(--ring-bg) 0);
            mask: radial-gradient(farthest-side, #0000 calc(99% - var(--border)), #000 calc(100% - var(--border)));
        }
    }
}

.piechart_wrapper {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.percent {
    position: absolute;
    font-size: 3.125rem;
    font-weight: 700;
}

.symbol {
    font-size: 1.563rem;
    font-weight: 100;
}

.title {
    margin-bottom: 0;
    text-align: center;
    font-size: 1.563rem;
}
